/* eslint-disable max-len */
import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Loading from 'react-lottie'
import { useLocation } from 'react-router-dom'
import Text from './../Components/Text'
import Grid from './../Components/VGrid'
import { colors } from '../Themes'
import { thankYouAnim } from '../Themes/Images'
import { TextInput } from '../Components/InputForm'
import Button from '../Components/Button'
import { useFormContact } from '../Utils/validation'
import axios from 'axios'
import { useMediaQuery, useTheme } from '@material-ui/core'

const FIRST_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: thankYouAnim,
  rendererSettings: {
    className: 'loginAnim',
  },
}

const SCREEN_CONTENT = {
  contactUs: 'contactUs',
  thankYou: 'thankYou',
}

const useStyles = makeStyles((theme) => ({
  // mainscreen  
  mainSection: {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: 44,
    transition: '0.5s',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      transition: '0.5s',
      paddingTop: 18,
      marginTop: 0,
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: 70,
      paddingRight: 70,
    },
    [theme.breakpoints.up(1920)]: {
      transition: '0.5s',
      paddingTop: 18,
      marginTop: 0,
      paddingInline: 300
    },
    [theme.breakpoints.up(2400)]: {
      transition: '0.5s',
      paddingTop: 18,
      marginTop: 0,
      paddingInline: 500
    },
  },
  mainWrapper: {
    width: '100%',
    display: 'flex',
    boxSizing: ' border-box',
    justifyContent: 'center',
    // [theme.breakpoints.down('sm')]: {
    //   flexDirection: 'column-reverse'
    // },
  },
  emailSupportContent: {
    padding: '0px 100px 0px 100px ',
    backgroundColor: colors.white,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginTop: 20,
      textAlign: 'center',
      justifyContent: 'center',
      padding: '20px 30px'
    },
  },
  input: {
    backgroundColor: 'white !important'
  },
  inputtrue: {
    border: `1px solid ${colors.secondary} !important`, 
    borderRadius: 10
  },
  errorInput: {
    color: colors.secondary,
    padding: '0px',
    fontSize: 16,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  // Form
  formSection: {
    backgroundColor: '#fff',
    borderRadius: 5,
    paddingInline: '32px',
    paddingTop: 24,
    paddingBottom: 32,
    maxWidth: 600,
    maxHeight: 390,
    boxShadow: '#00000029 0px 1px 6px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down(960)]: {
      marginLeft: 0,
      maxWidth: '100%'
    },
    [theme.breakpoints.down(600)]: {
      marginLeft: 0,
      maxWidth: '100%',
      maxHeight: 400,
    },
    [theme.breakpoints.down(340)]: {
      marginLeft: 0,
      maxWidth: '100%',
      maxHeight: 440,
    },
  },
  contactForm: {
    maxWidth: '100%'
  },
  // Thank you Message
  thankYouContainer: {
    display: 'flex',
    padding: '100px 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      padding: '70px 0 0',
    },
  },
  thankYouAnim: {
    flex: 1,
  },
  thankYouMessageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  thankYouMessage: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.drawer}`,
    borderRadius: 10,
    maxWidth: '70%',
    padding: '54px 60px 47px ',
    boxShadow: '1px 3px 14px ' +colors.gray,
    [theme.breakpoints.down('sm')]: {
      padding: '54px 20px 47px',
      maxWidth: '80%',
    },
  },
  thankYouText: {
    marginBottom: 20
  },
  image: {
    maxHeight: 470,
    [theme.breakpoints.down(960)]: {
      maxHeight: '100%',
    },
  }
}))

function ContactUs({ onFormSuccess }) {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(340))
  const isMobile2 = useMediaQuery(theme.breakpoints.down('sm'))

  const [values, setValues] = useState({
    email: '',
    clinicName: '',
    message: ''
  })

  const faqRef = useRef(null)
  const location = useLocation()

  const executeScroll = () => {
    faqRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (location.search === '?faq') {
      executeScroll()
    }
    return () => {
    }
  }, [location.search])

  const sendEmail=()=>{
    let body = {
      name: values.fullName,
      // eslint-disable-next-line camelcase
      clinic_name: values.clinicName,
      email: values.email,
      message: values.message,
      source: 'Podiatry2023'
    }
    setLoading(true)
    axios({
      method: 'POST',
      url: 'https://uphzr75ztc.execute-api.ap-southeast-2.amazonaws.com/prod/leads',
      data: body,
    }).then(
      // eslint-disable-next-line promise/always-return
      (res)=>{
        setLoading(false)
        onFormSuccess()
      }).catch(e=>{
      setLoading(false)
      console.log(e)
    })
  }

  const {
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
  } = useFormContact(sendEmail, setValues)

  useEffect(() => {
    if (
      Object.keys(errors).length !== 0 ||
      values.clinicName === '' ||
      values.email === ''
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [
    values?.clinicName,
    values?.email,
    errors,
  ])

  return (
    <>
      <Grid container alignItems="center" direction="column"  marginB={30} className={classes.mainSection}>
        <Grid container wrap='nowrap' className={classes.mainWrapper} >
          <Grid container lg={6} direction='column' style={{ maxHeight: (Boolean(errors?.clinicName) && Boolean(errors?.email) && isMobile) ? 470 : (Boolean(errors?.clinicName) && Boolean(errors?.email) ? 430 : 430) }}  className={classes.formSection}>
            <form className={classes.contactForm} onSubmit={handleSubmit}>
              <TextInput 
                costumizedInputStyles={
                  classes['input' + Boolean(errors?.clinicName)]
                }
                error={Boolean(errors?.clinicName)}
                name="clinicName"
                label="Clinic Name"  
                value={values?.clinicName || ''}
                setValue={(value) => handleChange(value, 'clinicName')}
                onBlur={(e) => handleOnBlur(e)}
              />
              {errors?.clinicName && (
                <p className={classes.errorInput}>{errors?.clinicName}</p>
              )}
              <TextInput
                costumizedInputStyles={classes['input' + Boolean(errors?.email)]}
                error={Boolean(errors?.email)}
                label="Email"
                name="email"
                value={values?.email || ''}
                setValue={(value) => handleChange(value, 'email')}
                onBlur={(e) => handleOnBlur(e)}
              />
              {errors?.email && (
                <p className={classes.errorInput}>{errors?.email}</p>
              )}
              <TextInput
                costumizedInputStyles={classes['input' + Boolean(errors?.message)]}
                style={{  minHeight: 90 }}
                error={Boolean(errors?.message)}
                label="Comments"
                name="message"
                value={values?.message || ''}
                setValue={(value) => handleChange(value, 'message')}
                // onBlur={(e) => handleOnBlur(e)}
              />
              {errors?.message && (
                <p className={classes.errorInput}>{errors?.message}</p>
              )}
              <Button 
                title="Join the mailing list" 
                type="submit"
                buttonType={ (disabled || loading) ? 'disabled':'primaryGreen'}
                disabled={disabled}
                style={{ marginTop: 24, width: '100% !important' }}
              />
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

function ThankYouForContacting({ goBack }){
  const classes = useStyles()

  const getStarted = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div className={classes.thankYouContainer}>
      <div className={classes.thankYouAnim}>
        <Loading options={FIRST_OPTIONS}  height={500} />
      </div>
      <div   className={classes.thankYouMessageWrapper}>
        <div className={classes.thankYouMessage}>
          <Text size="h3" weight="bold" color={colors.accent}  className={classes.thankYouText} > Thank you!<br/></Text>
          
          <Text size="body" weight="regular" color={colors.primary} ><br/>You can proceed to create your account, and we&apos;ll reach out to you to claim your benefits and provide further support.
            <br/>
            <br/>
          </Text>
          <Button 
            title="Create account" 
            buttonType='primary'
            onClick={() => getStarted('https://dashboard.dataplayer.io/signup')}
            style={{ marginTop: 50, maxWidth: '250px' }}
          />
        </div>
      </div>
    </div>
  )
}
const ContactUsSteps = {
  contactUs: ContactUs,
  thankYou: ThankYouForContacting,
}

function Podiatry(){
  const [contactStatus, setContactStatus] = useState(SCREEN_CONTENT.contactUs)
  const ContactSteps = ContactUsSteps[contactStatus]
  
  return (
    <ContactSteps
      onFormSuccess={() => setContactStatus(SCREEN_CONTENT.thankYou)}
      goBack={()=> setContactStatus(SCREEN_CONTENT.contactUs)}
    />
  )
}

export default Podiatry

