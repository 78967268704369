import React, { useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router'
import Text from './../Text'
import { colors } from '../../Themes'
import { useMediaQuery } from '@material-ui/core'

const THE_PRODUCT=[
  {
    id: '1',
    tittle: 'About us',
    link: '/about'
  },
  {
    id: '2',
    tittle: 'Pricing Plans',
    link: '/pricing',
    scrollTo: 'PLANS'
  },
  {
    id: '3',
    tittle: 'Privacy & Security',
    link: '/security'
  },
  {
    id: '4',
    tittle: 'Terms & Conditions',
    link: '/terms'
  },
  {
    id: '5',
    tittle: 'Cookies',
    link: '/cookies'
  }
]

const THE_PRODUCT_MOBILE=[
  {
    id: '3',
    tittle: 'Privacy & Security',
    link: '/security'
  },
  {
    id: '4',
    tittle: 'Terms & Conditions',
    link: '/terms'
  },
  {
    id: '5',
    tittle: 'Cookies',
    link: '/cookies'
  }
]


const COMMUNITY=[
  {
    id: '5',
    tittle: 'Features',
    link: '/about',
  },
  {
    id: '1',
    tittle: 'FAQ ',
    link: '/faq',
  },
  {
    id: '2',
    tittle: 'Blog',
    href: 'https://blog.dataplayer.io/'
  },
  {
    id: '3',
    tittle: 'Help Centre',
    link: '/help-centre',
  },
  {
    id: '4',
    tittle: 'Contact us',
    link: '/contactus'
  },
  
]

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: 'flex',
    flexFlow: 'column ',
    padding: '30px 10% 30px 10% ',
    [theme.breakpoints.down('md')]: {
      padding: '30px 20px',
    },
    [theme.breakpoints.up(2400)]: {
      padding: '30px 500px !important',
    },
    [theme.breakpoints.up(1920)]: {
      padding: '30px 300px',
    },
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  footerItem: {
    display: 'flex',
    flexFlow: 'column ',
    flex: 2,
    marginLeft: 50,
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: 20,
      marginTop: 5
    },
  },
  footerItem2: {
    display: 'flex',
    flexFlow: 'column ',
    flex: 2,
    marginLeft: 50,
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: 0,
      marginTop: 5
    },
  },
  logo: {
    height: 70,
    width: 200,
    imageRendering: '-webkit-optimize-contrast',
  },
  footerDesc: {
    [theme.breakpoints.only('sm')]: {
      width: '45%',
    },
  },
  footerTitle: {
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px !important'
    },
  },
  footerLink: {
    marginBottom: 13,
    cursor: 'pointer',
    fontSize: '16px',
    '&:hover': {
      color: colors.accentHover
    },
  },
  community: {
    marginBottom: 13,
    cursor: 'pointer',
    '&:hover': {
      color: colors.accentHover
    }
  },
  socialMedia: {
    display: 'flex',
    flexFlow: 'column ',
    marginLeft: 50,
    marginBottom: 120,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginBottom: 50,
    },
  },
  poweredBy: {
    display: 'flex',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px'
    },
  },
  link: {
    height: 40,
    width: 40
  },
  linkedinLogo: {
    height: 40,
    width: 40,
    marginLeft: 10
  },
}))

function NewFooter() {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const linkTo =(url, where)=>{
    history.push({
      pathname: url,
      search: where,
    })
  }

  useEffect(() => {
    if (location?.scrollTo) {
      document.getElementById(location?.scrollTo).scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [])

  return (
    <div className={classes.footerContainer}>
      <div className={classes.footerWrapper}  >
        <div className={classes.footerItem}>
          <div className={classes.logo}>
            <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/dataplayer%20logo.svg' alt='dataplayerLogo' loading='lazy' />
          </div>
          <Text style={{ fontSize: '16px' }} weight="regular" color={colors.primary} className={classes.footerDesc} >
          Dataplayer transforms your raw data from the practice management software into insightful visualisations and reports which help to keep track of your KPIs and take informed business decisions.
          </Text>
        </div>
        <div className={classes.footerItem2}>
          {isMobile  ? THE_PRODUCT_MOBILE.map((item)=>{
            return (  
              <div key={item.id} className={classes.footerLink}  onClick={item.scrollTo ? ()=> history.push({
                pathname: item.link,
                scrollTo: item.scrollTo
              }) : ()=> history.push(item.link)} >
                <Text style={{ fontSize: '16px' }} className={classes.footerLink}  weight="regular" color={colors.primary}>
                  {item?.tittle}
                </Text>
              </div>)
          }) : 
            THE_PRODUCT.map((item)=>{
              return (  
                <div key={item.id} className={classes.footerLink}  onClick={item.scrollTo ? ()=> history.push({
                  pathname: item.link,
                  scrollTo: item.scrollTo
                }) : ()=> history.push(item.link)} >
                  <Text style={{ fontSize: '16px' }} className={classes.footerLink}  weight="regular" color={colors.primary}>
                    {item?.tittle}
                  </Text>
                </div>)
            })}
        </div>
        <div className={classes.footerItem}>
          {isMobile ? null : 
            COMMUNITY.map((item)=>{
              return (  
                <>
                  {item.href ?
                    <a key={item.id} href={item?.href} target="_blank" rel="noreferrer" className={classes.community} style={{ textDecoration: 'none' }}>
                      <Text style={{ fontSize: '16px' }} className={classes.footerLink} weight="regular" color={colors.primary}>
                        {item?.tittle}
                      </Text>
                    </a>
                    : 
                    <div key={item.id} className={classes.footerLink}  onClick={()=>linkTo(item.link, item?.query)} >
                      <Text style={{ fontSize: '16px' }} className={classes.footerLink} weight="regular" color={colors.primary}>
                        {item?.tittle}
                      </Text>
                    </div>
                  }
                </>
              )
            })
          }
        </div>
        <div className={classes.socialMedia}>
          <Text style={{ fontSize: '16px' }} weight="bold"  className={classes.footerTitle} > Social media</Text>
          <div style={{ display: 'flex' }}>
            <a href="https://www.facebook.com/dataplayerio/" target="_blank" rel="noreferrer" className={classes.link}>
              <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/facebook.svg' alt='facebook' style={{ width: isMobile ? 32 : 42, height: isMobile ? 32 : 42, objectFit: 'contain' }} /></a>
            <a href="https://www.linkedin.com/company/dataplayerio/" target="_blank" rel="noreferrer" className={classes.linkedinLogo}>
              <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/linkedin.svg' alt='linkedin' style={{ width: isMobile ? 32 : 42, height: isMobile ? 32 : 42, objectFit: 'contain' }} /></a>
            <a href="https://www.instagram.com/dataplayer.io/" target="_blank" rel="noreferrer" className={classes.linkedinLogo}>
              <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/insta.svg' alt='insta' style={{ width: isMobile ? 32 : 42, height: isMobile ? 32 : 42, objectFit: 'contain' }}/></a>
          </div>
          <div className={classes.poweredBy}>
            <div style={{ display: 'flex', marginRight: isMobile ?  '20px' : 10, flexDirection: 'column' }}> 
              <Text size='body' weight='bold'>Powered by</Text>
              <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/tegeria.svg' loading='lazy' alt='tegeria' onClick={() => 
                window.open('https://tegeria.com/', '_blank')
              }
              style={{ marginTop: 10, cursor: 'pointer', width: isMobile ? 64 : 92, }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text size='body' weight='bold'>Partner of</Text>
              <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/aws.svg' alt='aws' loading='lazy' onClick={() => 
                window.open('https://partners.amazonaws.com/partners/0010h00001hfu2BAAQ/Tegeria', '_blank')
              } style={{ marginTop: 10, cursor: 'pointer', width: isMobile ? 46 : 54 }} />
            </div>
          </div>
        </div>
      </div>
      <Text size="footnote" weight="regular" color={colors.primary} style={{ alignSelf: 'center' }}>
      © Copyright {new Date().getFullYear()}, Dataplayer. All rights are reserved
      </Text>
    </div>
  )
}

export default NewFooter
