import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Drawer,
  Link,
  MenuItem,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState, useEffect, useRef } from 'react'
import { Link as RouterLink, NavLink, useHistory } from 'react-router-dom'
import CostumButton from '../Components/Button'
import { colors } from '../Themes'
import Text from '../Components/Text'

const desktopHeaderData = [
  {
    key: 1,
    label: 'Home',
    href: '/',
  },
  {
    key: 'about',
    label: 'About',
    href: '/about',
    isDropDown: true,
    dropDownElements: [
      {
        key: 13,
        label: 'Features',
        href: '/about',
      },
      {
        key: 9,
        label: 'Testimonials',
        href: '/about',
        scrollTo: 'TESTIMONIALS'
      },
    ]
  },
  {
    key: 3,
    label: 'Pricing',
    href: '/pricing',
    isDropDown: true,
    dropDownElements: [
      {
        key: 14,
        label: 'Plans',
        href: '/pricing',
        scrollTo: 'PLANS'
      },
      {
        key: 16,
        label: 'Add-ons',
        href: '/pricing',
        scrollTo: 'addOns'
      },
      // {
      //   key: 15,
      //   label: 'Calculate costs',
      //   href: '/pricing',
      //   scrollTo: 'COSTS'
      // },
    ]
  },
  {
    key: 'resources',
    label: 'Resources',
    href: '/faq',
    isDropDown: true,
    dropDownElements: [
      {
        key: 4,
        label: 'Blog',
        href: 'https://blog.dataplayer.io',
        newWindow: true
      },
      {
        key: 11,
        label: 'FAQ',
        href: '/faq',
      },
      {
        key: 20,
        label: 'Help Centre',
        href: '/help-centre',
      },
    ]
  },
  {
    key: 17,
    label: 'Contact',
    href: '/contactus',
  },
]

const headersData = [
  {
    key: 1,
    label: 'Home',
    href: '/',
  },
  {
    key: 'about',
    label: 'About',
    href: '/about',
    isDropDown: true,
    dropDownElements: [
      {
        key: 7,
        label: 'Features',
        href: '/about',
      },
      {
        key: 9,
        label: 'Testimonials',
        href: '/about',
        scrollTo: 'TESTIMONIALS'
      },
    ]
  },
  {
    key: 'pricing',
    label: 'Pricing',
    href: '/pricing',
    isDropDown: true,
    dropDownElements: [
      {
        key: 10,
        label: 'Plans',
        href: '/pricing',
        scrollTo: 'PLANS'
      },
      {
        key: 11,
        label: 'Add-ons',
        href: '/pricing',
        scrollTo: 'addOns'
      },
      // {
      //   key: 12,
      //   label: 'Calculate costs',
      //   href: '/pricing',
      //   scrollTo: 'COSTS'
      // },
    ]
  },
  {
    key: 'resources',
    label: 'Resources',
    href: '/faq',
    isDropDown: true,
    dropDownElements: [
      {
        key: 13,
        label: 'Blog',
        href: 'https://blog.dataplayer.io',
        newWindow: true
      },
      {
        key: 15,
        label: 'FAQ',
        href: '/faq',
      },
      {
        key: 20,
        label: 'Help Centre',
        href: '/help-centre',
      },
    ]
  },
  {
    key: 5,
    label: 'Contact',
    href: '/contactus',
  },
]

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#ffff',
    paddingInline: '5%',
    height: 80, 
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 2px 4px -1px rgb(206 206 206 / 90%)',
    [theme.breakpoints.down('md')]: {
      paddingInline: '20px',
    },
    [theme.breakpoints.up(1920)]: {
      paddingInline: '300px',
    },
  },
  logo: {
    height: 50,
    objectFit: 'contain',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      maxWidth: '100%',
     
    },
  },
  mobileLinks: {
    display: 'flex',
    flexDirection: 'column',
  },
  logoM: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      maxWidth: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '160px',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  drawerContainer: {
    padding: '0px 16px 20px 16px',
    flex: 1,
  },
  getStartedButten: {
    maxWidth: 200,
    marginLeft: '30px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 16,
      maxWidth: '100%'
    },
  },
  getStartedButten2: {
    width: '220px !important',
    position: 'absolute',
    bottom: 20,
    left: 17,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 16,
      width: '540px !important'
    },
    [theme.breakpoints.down('xs')]: {
      width: '220px !important',
      position: 'absolute',
      bottom: 20,
      left: 17,
    },
  },
  menuButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
  },
  loginButton: {
    minWidth: 75,
    marginLeft: '30px',
    textDecoration: 'none',
    alignSelf: 'center',
    padding: '0px',
    color: '#293E57',
    fontSize: 18,
    fontFamily: 'Roboto',
    '&:hover': {
      textDecoration: 'none',
      color: '#EE4352',
      fontWeight: 'bold'
    },
    '&:visited': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
      marginLeft: 0,
      padding: 0,
    },
  },
  loginButton2: {
    minWidth: 75,
    textDecoration: 'none',
    alignSelf: 'center',
    padding: '0px',
    color: '#293E57',
    fontSize: 18,
    fontFamily: 'Roboto',
    position: 'absolute',
    bottom: 80,
    left: 100,
    '&:hover': {
      textDecoration: 'none',
      color: colors.accentHover,
    },
    '&:visited': {
      textDecoration: 'none',
    },
    [theme.breakpoints.between(500, 960)]: {
      textAlign: 'center',
      minWidth: '400px !important',
      left: '85px !important'
    },
    [theme.breakpoints.down(500)]: {
      minWidth: 75,
      textDecoration: 'none',
      alignSelf: 'center',
      padding: '0px',
      color: '#293E57',
      fontSize: 18,
      fontFamily: 'Roboto',
      position: 'absolute',
      bottom: 80,
      left: 100,
    },
  },
  menuButton: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    marginLeft: '30px',
    textDecoration: 'none',
    color: colors.primary,
    padding: 8,
    '&:hover': {
      textDecoration: 'none',
      color: colors.primary,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginLeft: '15px',
    },
  },
  menuButton2: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    textDecoration: 'none',
    color: colors.primary,
    paddingTop: 8,
    paddingBottom: 8,
  },
  menuButtonActive: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    marginLeft: '30px',
    textDecoration: 'none',
    color: colors.secondary,
    padding: 8,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
      color: colors.secondary,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginLeft: '15px',
    },
  },
  drawerRoot: {
    width: '70%',
  },
  svg: {
    alignItems: 'center', 
    display: 'flex', 
    height: 30,
    [theme.breakpoints.down('md')]: {
      width: 100
    },
  }
}))

export default function NavBar() {
  const classes = useStyles()
  const currentScreen = useHistory().location.pathname
  const history = useHistory()
  const [dropDrownHovered, setDropDownHovered] = useState({
    about: false,
    pricing: false,
    resources: false
  })

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  })

  const { mobileView, drawerOpen } = state

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }))
    }
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  const getStarted = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const displayDesktop = () => {

    return (
      <Toolbar className={toolbar}>
        <RouterLink to="/">
          <div  className={classes.svg}>
            <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/dataplayer%20logo.svg' alt='dataplayerLogo'/>
          </div>
        </RouterLink>
        <div
          className={classes.menuButtonContainer}
        >{GetMenuButtons()}
          <a href="https://dashboard.dataplayer.io/" target="_blank" rel="noreferrer" className={classes.loginButton} >
            {/* <CostumButton  title='Login' buttonType="link"  /> */}
            Log in
          </a>
          <CostumButton
            
            title='Get Started'
            buttonType="primaryGreen"
            className={classes.getStartedButten}
            onClick={() => getStarted('https://dashboard.dataplayer.io/signup')}
          />
        </div>
      </Toolbar>
    )
  }

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () => {
      setState((prevState) => ({ ...prevState, drawerOpen: false }))
      setDropDownHovered( (prev) => [{
        ...prev,
        about: false,
        pricing: false,
        resources: false
      }])
    }
      
    return (
      <Toolbar
        {...{
          className: classes.logo
        }}
      >
        <RouterLink to="/">
          <div className={classes.logoM}>
            <div style={{ alignItems: 'center', display: 'flex', height: 100 }}>
              <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/dataplayer%20logo.svg' alt='dataplayerLogo' />
            </div>
          </div>
        </RouterLink>
        <IconButton
          {...{
            edge: 'end',
            color: 'inhert',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: handleDrawerOpen,
            style: {
              paddingTop: 25,
              position: 'absolute',
              right: 0
            },
          }}
        >
          <MenuIcon style={{
            width: 32,
            height: 32
          }} />
        </IconButton>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          classes={{ paper: classes.drawerRoot }}
        >
          <div className={classes.drawerContainer}>
            <div className={classes.logoM} style={{ maxWidth: '100%', justifyContent: 'space-between', height: 50 }}>
              <div style={{ alignItems: 'center', display: 'flex', height: 70 }}>
                <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/dataplayer%20logo.svg' alt='dataplayerLogo' width={120} />
              </div>
              <IconButton
                disableFocusRipple
                centerRipple={true}
                onClick={handleDrawerClose}
                style={{ paddingTop: 16, paddingLeft: 82, height: 70 }}
              >
                <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/close.svg' alt='close'  style={{ width: 16 }} />
              </IconButton>
            </div>
            <div style={{ height: 1, borderBottom: `1px solid ${colors.background}`, marginBottom: 10, marginTop: 20  }} />
            <div className={classes.mobileLinks}>
              {getDrawerChoices()}
              <div style={{
                width: '100%',
                height: 2,
                backgroundColor: '#f6f6f6',
                marginTop: 20,
                marginBottom: 20,
              }} />
              <a href="https://dashboard.dataplayer.io/" target="_blank" rel="noreferrer" className={classes.loginButton2} >
                Log in
              </a>
              <CostumButton
                title='Get Started'
                buttonType="primaryGreen"
                className={classes.getStartedButten2}
                onClick={() => getStarted('https://dashboard.dataplayer.io/signup')}
              />
            </div>
          </div>
        </Drawer>
      </Toolbar>
    )
  }

  const getDrawerChoices = () => {

    const Item = ({ onClick, title, isLast, disabled }) => {
      return (
        <div style={{ minWidth: 120, cursor: disabled ? 'default' : 'pointer', }} onClick={disabled ? null : onClick}>
          <Text color={disabled ? '#dbdddf' : null} size={'footnote'}>{title}</Text>
          {!isLast && <hr style={{ marginTop: 8, marginBottom: 8 }} />}
        </div>
      )
    }

    return headersData.map(({ key, label, href, disabled = false, newWindow = false, scrollTo = false, isDropDown, dropDownElements = [] }) => {
      const linkName = dropDrownHovered[key] ?  
        <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/arrow.svg'alt='arrowUp' style={{ transform: 'rotateX(0.5turn)', fill: '#293E57 !important', width: 18 }} /> 
        : 
        <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/arrow.svg' alt='arrowDown' style={{ fill: '#293E57 !important', width: 18 }} />
      if (isDropDown) {

        const handleClick = (key) => {
          const stateCopy = { ...dropDrownHovered }
          const nextValue = !stateCopy[key]
          Object.keys(stateCopy).forEach(key => stateCopy[key] = false)
          stateCopy[key] = nextValue
          setDropDownHovered(stateCopy)
        }
        
        return (

          <div key={key}>
            <div style={{ display: 'flex', position: 'relative', cursor: 'pointer', alignItems: 'center' }}>
              <div style={{ cursor: 'pointer', display: 'flex', color: href === currentScreen ? 'rgb(238, 67, 82)' : 'rgb(41, 62, 87)', fontWeight: href === currentScreen ? 'bold' : '400' }} onClick={() => {
                setDropDownHovered( (prev) => [{
                  ...prev,
                  about: false,
                  pricing: false,
                  resources: false
                }])
                setState((prevState) => ({ ...prevState, drawerOpen: false }))
                history.push(href)}
              } className={classes.menuButton2}>{label}
              </div>
              <div className='arrow-fill' key={key} style={{ display: 'flex', position: 'absolute', right: 0 }} onClick={() => handleClick(key)}>{linkName}</div>
            </div>
            {dropDrownHovered && <div
              style={{
                display: dropDrownHovered[key] ? 'block' : 'none',
                padding: 16,
                backgroundColor: colors.background,
                left: 60,
                borderRadius: 6,
                zIndex: 9999,
                color: 'white',
                top: 10,
              }}
            >
              {dropDownElements.map((item, index) => {
                const { href, label, disabled, key, newWindow = false, scrollTo } = item
                const navigate = () => {
                  setDropDownHovered( (prev) => [{
                    ...prev,
                    about: false,
                    pricing: false,
                    resources: false
                  }])
                  // if user is in the same screen
                  if (href === location?.pathname) {
                    if (!scrollTo) {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      },
                      setState((prevState) => ({ ...prevState, drawerOpen: false })))
                    }
                    else if ( scrollTo === 'addOns' || scrollTo === 'COSTS') {
                      const yOffset = -70
                      const y = document?.getElementById(scrollTo).getBoundingClientRect().top + window.scrollY + yOffset
                      window.scrollTo({ top: y, behavior: 'smooth', block: 'start' })
                      setState((prevState) => ({ ...prevState, drawerOpen: false }))
                    }
                    else if (scrollTo === 'TESTIMONIALS') {
                      const yOffset = -260
                      const y = document?.getElementById(scrollTo).getBoundingClientRect().top + window.scrollY + yOffset
                      window.scrollTo({ top: y, behavior: 'smooth', block: 'start' })
                      setState((prevState) => ({ ...prevState, drawerOpen: false }))
                    }
                    else if (scrollTo === 'PLANS') {
                      const yOffset = -360
                      const y = document?.getElementById(scrollTo).getBoundingClientRect().top + window.scrollY + yOffset
                      window.scrollTo({ top: y, behavior: 'smooth', block: 'start' })
                      setState((prevState) => ({ ...prevState, drawerOpen: false }))
                    }
                    else {
                      document?.getElementById(scrollTo).scrollIntoView({ behavior: 'smooth', block: 'start' })
                      setState((prevState) => ({ ...prevState, drawerOpen: false }))
                    }
                  } else if (newWindow) {
                    window.open(href, '_blank')
                    setState((prevState) => ({ ...prevState, drawerOpen: false }))
                  } 
                  else {
                    history.push({
                      pathname: href,
                      scrollTo: scrollTo
                    })
                    setState((prevState) => ({ ...prevState, drawerOpen: false }))
                  }
                }
                
                return (
                  <Item
                    key={key}
                    onClick={navigate}
                    title={label}
                    isLast={index === dropDownElements.length - 1}
                    disabled={disabled}
                  />
                )
              })}
            </div>}
            
          </div>
        )
      }
      if (newWindow) {
        return (
          <MenuItem
            onClick={() => {
              if (newWindow) {
                setState((prevState) => ({ ...prevState, drawerOpen: false }))
                getStarted(href)
              }
            }}
            key={label + key}
            disabled={disabled}
            style={{ paddingLeft: 0, color: href === currentScreen ? 'rgb(238, 67, 82)' : 'rgb(41, 62, 87)' }}>{label}</MenuItem>
        )
      }
      return (
        <Link
          disab
          key={label + key}
          onClick={() => {
            if (href === location?.pathname && !scrollTo) {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })
            }
            if (newWindow) {
              setState((prevState) => ({ ...prevState, drawerOpen: false }))
              getStarted(href)
            }
            if (!disabled) {
              setState((prevState) => ({ ...prevState, drawerOpen: false }))
            }
          }}
          component={RouterLink}
          to={disabled ? '#' : {
            pathname: href,
            scrollTo: scrollTo,
          }}
          style={{ textDecoration: 'none', color: colors.primary }}
          activeClassName={classes.menuButtonActive}
          exact={true}
        >
          <MenuItem disabled={disabled} style={{ paddingLeft: 0, color: href === currentScreen ? colors.secondary : null, fontWeight: href === currentScreen ? 'bold' : '400', fontSize: '18px' }}>{label}</MenuItem>
        </Link>
      )
    })
  }

  const GetMenuButtons = () => {

    return desktopHeaderData.map(({
      key,
      label,
      href,
      isDropDown = false,
      newWindow = false,
      isDisabled = false,
      dropDownElements = [],
      scrollTo = false
    }) => {
      if (isDropDown) {
        const updateState = (key, state) => {
          setDropDownHovered(prev => ({
            ...prev,
            [key]: state
          }))
        }

        const Item = ({ onClick, title, isLast, disabled }) => {
          return (
            <div style={{ minWidth: 200, cursor: disabled ? 'default' : 'pointer' }} onClick={disabled ? null : onClick}>
              <Text color={disabled ? '#dbdddf' : null} size={'footnote'}>{title}</Text>
              {!isLast && <hr style={{ marginTop: 8, marginBottom: 8 }} />}
            </div>
          )
        }

        return (

          <div style={{ position: 'relative' }} key={key} onMouseEnter={() => { updateState(key, true) }} onMouseLeave={() => { updateState(key, false) }}>
            <div onClick={() => {
              updateState(key, !dropDrownHovered[key])
              history.push(href)
            }} style={{ cursor: 'pointer', color: href === currentScreen ? 'rgb(238, 67, 82)' : 'rgb(41, 62, 87)', fontWeight: href === currentScreen ? 'bold' : '400' }} className={classes.menuButton}>{label}
            </div>
            <div
              style={{
                position: 'absolute',
                visibility: dropDrownHovered[key] ? 'visible' : 'hidden',
                padding: 16,
                boxShadow: '#efefef 0px 0px 2px 1px',
                backgroundColor: 'white',
                left: 30,
                borderRadius: 6
              }}
            >
              {dropDownElements.map((item, index) => {
                const { href, label, disabled, key, newWindow = false, scrollTo = null } = item
                const navigate = () => {
                  // if user is in the same screen
                  if (href === location?.pathname) {
                    if (!scrollTo) {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      })
                    } 
                    else if (scrollTo === 'COSTS') {
                      const yOffset = -90
                      const y = document?.getElementById(scrollTo).getBoundingClientRect().top + window.scrollY + yOffset
                      window.scrollTo({ top: y, behavior: 'smooth', block: 'center' })
                    }
                    else {
                      document?.getElementById(scrollTo).scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                  } else if (newWindow) {
                    window.open(href, '_blank')
                  } 
                  else {
                    history.push({
                      pathname: href,
                      scrollTo: scrollTo
                    })
                  }
                }
                return (
                  <Item
                    key={key}
                    onClick={navigate}
                    title={label}
                    isLast={index === dropDownElements.length - 1}
                    disabled={disabled}
                  />
                )
              })}
            </div>
          </div>
        )
      }

      // New item since navlink considers pressing the item navigation to a screen and the button gets the current screen color
      if (newWindow) {
        return (
          <div
            key={key}
            className={classes.menuButton}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (newWindow) {
                window.open(href, '_blank')
              }
            }}
          >
            {label}
          </div>
        )
      }

      return (
        <>
          <NavLink
            key={key}
            className={classes.menuButton}
            to={href}
            activeClassName={classes.menuButtonActive}
            exact={true}
            onClick={() => {
              if (href === location?.pathname && !scrollTo) {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
                return
              }
              if (newWindow) {
                window.open(href, '_blank')
              }
            }}
          >
            {label}
          </NavLink>
        </>
      )
    })
  }

  return (
    <AppBar className={classes.header}>
      {mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  )
}