import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Text from './../Text'
import Grid from './../VGrid'
import Button from './../Button'
import { colors } from '../../Themes'

function TryDP() {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '20px 10%',
      [theme.breakpoints.down('xs')]: {
        padding: '20px',
      },
    },
    info: {
      textAlign: 'center',
      margin: '20px 0'
    },
    text: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px !important',
        textAlign: 'center'
      },
      [theme.breakpoints.between(300, 380)]: {
        fontSize: '22px !important',
        textAlign: 'center'
      },
      [theme.breakpoints.between(380, 390)]: {
        fontSize: '24px !important',
        textAlign: 'center'
      },
    },
    gridButton: {
      display: 'flex',
      justifyContent: 'center'
    }
  }))

  const classes = useStyles()

  const getStarted = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  
  return (
    <>
      <Grid id='footer' container className={classes.root} justify="center">
        <Text size='h3' className={classes.text} color={colors.white} weight="bold">Get started now with a 14-day free trial!</Text>
        <Grid container justify="center">
          <Grid xl={6} lg={6} md={7} sm={10} xs={10} className={classes.info}>
            <Text size='footnote' color={colors.white} weight="regular">
            No commitment or payment information is required. 
            </Text>
            <Text  size='footnote' color={colors.white} weight="regular">You can instantly access your own data in the dashboard and explore all the features. </Text>
          </Grid>
        </Grid>
        <Grid container justify="center" marginT={20}>
          <Grid className={classes.gridButton} xl={12} lg={4} md={5} sm={10} xs={10}>
            <Button style={{ maxWidth: '200px !important' }} key="footer" title="Get Started" buttonType="primaryMaybe" onClick={() => getStarted('https://dashboard.dataplayer.io/signup')} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default TryDP
